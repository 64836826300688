var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "staked-status-container mx-auto" }, [
    _vm.loading
      ? _c(
          "div",
          [
            _c("v-skeleton-loader", {
              attrs: { width: "150px", type: "list-item" },
            }),
            _vm._l(3, function (idx) {
              return _c("v-skeleton-loader", {
                key: idx,
                staticClass: "mb-4",
                attrs: { height: "100px", type: "image" },
              })
            }),
          ],
          2
        )
      : _vm._e(),
    !_vm.loading
      ? _c("div", [
          _vm.validators.length === 0 && _vm.justStakedValidator.length === 0
            ? _c("div", [_vm._v(" You are currently not staking any eth. ")])
            : _vm._e(),
          _vm.allPendingValidators.length > 0
            ? _c(
                "div",
                { staticClass: "pb-8" },
                [
                  _c("span", { staticClass: "mew-heading-3" }, [
                    _vm._v("Pending"),
                  ]),
                  _vm._l(_vm.allPendingValidators, function (pending, idx) {
                    return _c(
                      "div",
                      {
                        key: pending + idx,
                        staticClass:
                          "mt-4 d-flex flex-column align-center cursor-pointer justify-space-between",
                      },
                      [
                        _c(
                          "div",
                          {
                            class: [
                              "rounded-t-lg header-container greyLight pa-5 full-width d-flex flex-row align-center justify-space-between",
                              _vm.isExpanded(idx)
                                ? "rounded-t-lg"
                                : "rounded-lg",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.expand(idx)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "left-container d-flex" },
                              [
                                _c("img", {
                                  attrs: {
                                    src: _vm.iconETHNavy,
                                    height: "22",
                                    alt: "ethereum",
                                  },
                                }),
                                _c("div", { staticClass: "ml-3" }, [
                                  _c("div", { staticClass: "mew-heading-3" }, [
                                    _vm._v(" " + _vm._s(pending.amount) + " "),
                                    _c("span", { staticClass: "mew-caption" }, [
                                      _vm._v("ETH"),
                                    ]),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "textMedium--text mt-1" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(pending.amountFiat) + " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              [
                                _c("v-progress-circular", {
                                  attrs: {
                                    size: "18",
                                    width: "2",
                                    color: "greenPrimary",
                                    indeterminate: "",
                                  },
                                }),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "ml-5",
                                    attrs: { color: "greyPrimary", size: "22" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.isExpanded(idx)
                                          ? "mdi-chevron-up"
                                          : "mdi-chevron-down"
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm.isExpanded(idx)
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "border-container rounded-b-lg full-width pa-5",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "mt-5 mb-8 font-weight-bold" },
                                  [_vm._v("More Info")]
                                ),
                                pending.status.toLowerCase() ===
                                _vm.STATUS_TYPES.CREATED
                                  ? _c(
                                      "div",
                                      { staticClass: "d-flex" },
                                      [
                                        _c("v-progress-circular", {
                                          staticClass: "ml-4",
                                          attrs: {
                                            size: "18",
                                            width: "2",
                                            color: "greenPrimary",
                                            indeterminate: "",
                                          },
                                        }),
                                        _c("span", { staticClass: "ml-2" }, [
                                          _vm._v(
                                            _vm._s(
                                              !pending.justStaked ||
                                                _vm.txReceipt
                                                ? "Processing"
                                                : "Sending"
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                pending.status.toLowerCase() ===
                                  _vm.STATUS_TYPES.DEPOSITED ||
                                pending.status.toLowerCase() ===
                                  _vm.STATUS_TYPES.PENDING
                                  ? _c(
                                      "div",
                                      { staticClass: "d-flex" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              size: "20",
                                              color: "greenPrimary",
                                            },
                                          },
                                          [_vm._v("mdi-check-circle")]
                                        ),
                                        _c("span", { staticClass: "ml-2" }, [
                                          _vm._v("Deposited"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                pending.status.toLowerCase() ===
                                _vm.STATUS_TYPES.FAILED
                                  ? _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              size: "20",
                                              color: "redPrimary",
                                            },
                                          },
                                          [_vm._v("mdi-close-circle")]
                                        ),
                                        _c("span", { staticClass: "ml-2" }, [
                                          _vm._v("Failed"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c("div", { staticClass: "d-flex mt-6" }, [
                                  pending.etherscanUrl
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "font-weight-medium",
                                          attrs: {
                                            rel: "noopener noreferrer",
                                            href: pending.etherscanUrl,
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v("View on Etherscan "),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "greenPrimary",
                                                size: "14",
                                              },
                                            },
                                            [_vm._v("mdi-open-in-new")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  pending.ethVmUrl && !_vm.onGoerli
                                    ? _c(
                                        "a",
                                        {
                                          staticClass:
                                            "font-weight-medium ml-5",
                                          attrs: {
                                            rel: "noopener noreferrer",
                                            href: pending.ethVmUrl,
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v("View on EthVM "),
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                color: "greenPrimary",
                                                size: "14",
                                              },
                                            },
                                            [_vm._v("mdi-open-in-new")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                                pending.status.toLowerCase() ===
                                  _vm.STATUS_TYPES.DEPOSITED ||
                                pending.status.toLowerCase() ===
                                  _vm.STATUS_TYPES.PENDING
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "d-flex flex-column mt-9",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "d-flex align-center",
                                          },
                                          [
                                            _c("v-progress-circular", {
                                              attrs: {
                                                size: "18",
                                                width: "2",
                                                color: "greenPrimary",
                                                indeterminate: "",
                                              },
                                            }),
                                            _c(
                                              "span",
                                              { staticClass: "ml-2" },
                                              [
                                                _vm._v(
                                                  "Waiting for validator activation"
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "mew-label font-weight-medium textLight--text mt-1",
                                          },
                                          [
                                            _vm._v(
                                              "Estimated wait time: " +
                                                _vm._s(
                                                  pending.estimatedWaitTime
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        pending.url
                                          ? _c(
                                              "a",
                                              {
                                                staticClass:
                                                  "font-weight-medium mt-5",
                                                attrs: {
                                                  rel: "noopener noreferrer",
                                                  href: pending.url,
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "Eth2 Beacon Chain transactions "
                                                ),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: "greenPrimary",
                                                      size: "14",
                                                    },
                                                  },
                                                  [_vm._v("mdi-open-in-new")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.activeValidators.length > 0
            ? _c(
                "div",
                [
                  _c("span", { staticClass: "mew-heading-3" }, [
                    _vm._v("Active"),
                  ]),
                  _vm._l(_vm.activeValidators, function (active, idx) {
                    return _c(
                      "div",
                      {
                        key: active + idx,
                        staticClass:
                          "border-container rounded-lg pa-5 mt-4 d-flex justify-space-between",
                      },
                      [
                        _c("div", { staticClass: "left-container d-flex" }, [
                          _c("img", {
                            attrs: {
                              src: _vm.iconETHNavy,
                              height: "26",
                              alt: "ethereum",
                            },
                          }),
                          _c(
                            "div",
                            { staticClass: "left-container-details ml-3" },
                            [
                              _c("div", { staticClass: "mew-heading-2" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(active.totalBalanceETH + " ETH") +
                                    " "
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "font-weight-medium mt-1" },
                                [
                                  _vm._v(
                                    " " + _vm._s(active.totalBalanceFiat) + " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "textLight--text mt-2" },
                                [
                                  _vm._v(" Earned "),
                                  _c(
                                    "span",
                                    { staticClass: "greenPrimary--text" },
                                    [_vm._v(_vm._s(active.earned + " ETH"))]
                                  ),
                                  _vm._v(
                                    " · Average APR " +
                                      _vm._s(active.averageApr) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "a",
                          {
                            staticClass: "font-weight-medium",
                            attrs: {
                              rel: "noopener noreferrer",
                              href: active.url,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v("View Eth2 address "),
                            _c(
                              "v-icon",
                              { attrs: { color: "greenPrimary", size: "14" } },
                              [_vm._v("mdi-open-in-new")]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }